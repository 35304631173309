require('./bespoke_core');
require('./bespoke_calendar');

//https://github.com/timyboy12345/Rating.js
require('./rating');

$(document).ready(function () {

    $(document).on('change', '.report-hundred-year-range-datepicker.livewire-date', function (event) {
        this.dispatchEvent(new InputEvent('input'));
    });

    $('body').on('focus', ".report-hundred-year-range-datepicker", function () {
        let InputName = $(this).data('picker_type');
        let EndDateInput = null;

        if (InputName === "start") {
            EndDateInput = $(this).parents('div.row').children().find('[data-picker_type=end]');
            let StartIn = this;
            let EndDateVal = $(EndDateInput).val();
            if ($(this).changeDate === undefined) {
                $(this).datepicker({
                    format: 'dd/mm/yyyy',
                    autoclose: true,
                    startDate: "-100y",
                    zIndexOffset: 2000,
                    container: '#date_picker_area',
                    // orientation: 'bottom',
                });
            }
            $(this).on('changeDate', function (e) {
                EndDateInput.datepicker('destroy');
                let startDate = new Date(e.date.valueOf());
                startDate.setDate(startDate.getDate(new Date(e.date.valueOf())));
                $(EndDateInput).datepicker({
                    format: 'dd/mm/yyyy',
                    autoclose: true,
                    startDate: startDate,
                    zIndexOffset: 2000,
                    container: '#date_picker_area',
                    // orientation: 'bottom',
                });
            });
        } else {
            let StartDateInput = $(this).parents('div.row').children().find('[data-picker_type=start]');
            let StartDateVal = $(StartDateInput).val();
            if ($(this).changeDate === undefined) {
                $(this).datepicker({
                    format: 'dd/mm/yyyy',
                    autoclose: true,
                    startDate: "-10y",
                    zIndexOffset: 2000,
                    container: '#date_picker_area',
                    // orientation: 'bottom',
                });
            }
            $(this).on('changeDate', function (e) {
                StartDateInput.datepicker('destroy');
                let FromEndDate = new Date(e.date.valueOf());
                FromEndDate.setDate(FromEndDate.getDate(new Date(e.date.valueOf())));
                $(StartDateInput).datepicker({
                    format: 'dd/mm/yyyy',
                    autoclose: true,
                    startDate: "-10y",
                    endDate: FromEndDate,
                    zIndexOffset: 2000,
                    container: '#date_picker_area',
                    // orientation: 'bottom',
                });
            });
        }//end else
    });

    $('#global_third_layer_modal_area').on('hidden.bs.modal', function (e) {
        if (($("#global_modal_area").find('.modal').data('bs.modal') || $("#global_secondary_modal_area").find('.modal').data('bs.modal') || {})._isShown) {
            $('body').addClass('modal-open');
        }
    });

    $(document).ready(function () {
        //We need this to re-setup star ratings on modals etc
        window.setup_star_ratings = function () {
            $(function () {
                $("[data-rating-stars]").each(function () {
                    // Get all data-rating attributes
                    let d = {},
                        re_dataAttr = /^data-rating\-(.+)$/;

                    $.each($(this).get(0).attributes, function (index, attr) {
                        if (re_dataAttr.test(attr.nodeName)) {
                            let key = attr.nodeName.match(re_dataAttr)[1];
                            d[key] = attr.nodeValue;
                        }
                    });

                    // Create the click event handler
                    if (d.input != null) {
                        d.click = function (e) {
                            $(d.input).val(e.stars);
                        }
                    }

                    // Run the rating function on the element
                    $(this).rating(d);
                });
            });
        }
    });
});
